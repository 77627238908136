<template>
    <div>
        <el-form :model="form_data" :rules="form_rules" :ref="form_name" :disabled="formDisabled" label-position="right" label-width="100px" size="mini">
            <el-form-item label="ID:"><span v-if="form_data.id">{{form_data.id}}</span></el-form-item>
            <el-form-item label="配置组别:" prop="group_name">
                <el-input v-model="form_data.group_name"></el-input>
            </el-form-item>
            <el-form-item label="配置编号:" prop="group_code">
                <el-input v-model="form_data.group_code"></el-input>
            </el-form-item>
        </el-form>
        <div style="text-align:right;">
            <el-button v-if="action!=='view'" type="primary" @click="submitForm(form_name)" size="mini">提交编辑</el-button>
            <el-button @click="onCloseDialog" size="mini">关闭</el-button>
        </div>
    </div>
</template>

<script>
    import {getDetail, submit} from "@/api/vstar/setting-group";

    export default {
        name: "setting-group-detail",
        props:{
            action:{
                value:String,
                default:'add'
            },
            rowId:{
                value:String,
            }
        },
        data(){
            return{
                form_name:'setting_group_detail',
                form_data:{},
                form_rules:{},
            }
        },
        computed:{
            formDisabled(){
                return this.action === 'view' || !this.action;
            }
        },
        created(){
            this.initPage()
        },
        methods:{
            initPage(){
                if(this.rowId) {
                    getDetail(this.rowId).then(resp => {
                        this.form_data = resp.data
                    })
                }
            },
            onCloseDialog(){
                this.$emit("onTellDialogClose")
            },
            submitForm(formName){
                this.$refs[formName].validate((valid)=>{
                    if(valid){
                        submit(this.form_data).then(()=>{
                            this.$emit('onTellDialogClose')
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
